<template>
  <div>
    <v-card
      class="mb-4 list-item"
      outlined
    >
      <v-card-title class="py-2">
        <v-row>
          <v-col class="d-flex justify-start align-center">
            <v-tooltip
              :disabled="$vuetify.breakpoint.mdAndDown"
              open-delay="1000"
              top
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  class="mr-3"
                  fab
                  :outlined="!data.markedForExport"
                  :color="data.markedForExport ? 'teal' : 'grey'"
                  :dark="data.markedForExport"
                  small
                  @click.stop="toggleExport()"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon>mdi-check-bold</v-icon>
                </v-btn>
              </template>
              <span>Selecteer voor export</span>
            </v-tooltip>
            <v-tooltip
              :disabled="$vuetify.breakpoint.mdAndDown"
              open-delay="1000"
              top
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  @click.stop="showForm(data)"
                  class="mr-2"
                  icon
                  color="orange"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon>mdi-square-edit-outline</v-icon>
                </v-btn>
              </template>
              <span>Bewerken</span>
            </v-tooltip>
            <v-tooltip
              :disabled="$vuetify.breakpoint.mdAndDown"
              open-delay="1000"
              top
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  color="red"
                  @click.stop="confirmRemoveListItem()"
                  :loading="removeBusy"
                  :disabled="removeDisabled"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon>mdi-delete-outline</v-icon>
                </v-btn>
              </template>
              <span>Verwijderen</span>
            </v-tooltip>
          </v-col>
          <v-col class="d-flex justify-end align-center ma-0 py-0">
            <v-btn
              icon
              @click.stop="moveUp()"
              :disabled="index == 0"
            >
              <v-icon>mdi-arrow-up</v-icon>
            </v-btn>

            <v-btn
              icon
              @click.stop="moveDown()"
              :disabled="index == totalItems - 1"
            >
              <v-icon>mdi-arrow-down</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-title>
      <v-divider />
      <v-card-text>
        <p class="text-subtitle-1">
          <span class="font-italic">{{ typeName }}</span>
          &mdash; {{ data.title }}
        </p>
        <p
          v-if="date"
        >
          {{ dateAsString(date) }}
        </p>
        <p
          v-if="!date"
          class="text--disabled"
        >
          Geen datum opgegeven
        </p>
        <div
          class="custom-description"
          v-if="data.description && data.description !== '<p></p>'"
          v-html="data.description"
        >
        </div>
        <p
          v-if="!data.description || data.description === '<p></p>'"
          class="text--disabled"
        >
          Geen beschrijving opgegeven
        </p>
        <p
          v-if="url || data.document"
        >
          Bron:
          <a
            :href="`${url.startsWith('http') ? `${url}` : `//${url}`}`"
            rel="noopener noreferrer"
            target="_blank"
            class="text--primary text-decoration-none"
          >
            {{ url | urlToSourceName }}
          </a>
          <span v-if="data.document">
            /
            <a
              :href="documentPolpoUrl"
              target="_blank"
              class="text--primary text-decoration-none"
            >
              Open in Polpo
            </a>
          </span>
        </p>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import urlToSourceName from '@/filters/urlToSourceName';
import EventBus from '@/event-bus';
import { showDialog } from '@/helpers/dialogHelper';
import DocumentTypesMixin from '@/mixins/DocumentTypesMixin';
import ListManagerItemForm from './ListManagerItemForm.vue';

export default {
  name: 'ListManagerItem',
  filters: {
    urlToSourceName,
  },
  props: {
    index: {
      type: Number,
      required: true,
    },
    totalItems: {
      type: Number,
      required: true,
    },
    data: {
      type: Object,
      required: true,
    },
  },

  mixins: [DocumentTypesMixin],

  data() {
    return {
      showArchived: false,
      referrer: false,
      removeBusy: false,
      removeDisabled: false,
      formData: null,
      formVisible: false,
    };
  },
  computed: {
    documentPolpoUrl() {
      let europarlPrefix = ''; // NL dashboard -> no prefix
      if (this.data.document.type.startsWith('EUROPARL-') || this.data.document.type.startsWith('EUROCOM-')) {
        europarlPrefix = '/eu';
      }
      return `${europarlPrefix}/document/${this.data.document.id}`;
    },
    url() {
      return this.data.document ? this.data.document.sourceUrl : this.data.customDocument.sourceUrl;
    },
    date() {
      return this.data.document ? this.data.document.date : this.data.customDocument.date;
    },
    typeName() {
      if (!this.documentTypesMixinReady) return undefined;
      return this.data.document
        ? this.getLocalizationForDocTypeId(this.data.document.type)
        : this.data.customDocument.sourceName;
    },
  },
  methods: {
    confirmRemoveListItem() {
      EventBus.$on('submitConfirmDialog', this.removeListItem);
      this.$store.dispatch('setConfirmDialogHeader', 'Update verwijderen');
      this.$store.dispatch(
        'setConfirmDialogBody',
        'Weet je zeker dat je deze update wilt verwijderen?',
      );
      this.$store.dispatch('setConfirmDialogVisible', true);
    },
    async removeListItem() {
      this.busyRemove = true;
      this.disabled = true;

      await this.$store.dispatch('removeListItem', this.data);
      this.busyRemove = false;
      this.disabled = false;

      this.$gtag.event('list-manager-item-remove', {
        event_category: 'list-manager',
      });

      EventBus.$off('submitConfirmDialog');
    },
    dateAsString(date) {
      return this.$moment(date).format('dddd D MMMM YYYY');
    },
    async toggleExport() {
      await this.$store.dispatch('toggleListItemMarkedForExport', this.data);
      this.$gtag.event('list-manager-item-toggle-export', {
        event_category: 'list-manager',
      });
    },
    async moveUp() {
      await this.$store.dispatch('moveListItem', {
        index: this.index,
        direction: -1,
      });
      this.$gtag.event('list-manager-item-move', {
        event_category: 'list-manager',
        event_label: 'list-manager-item-move-up',
      });
    },
    async moveDown() {
      await this.$store.dispatch('moveListItem', {
        index: this.index,
        direction: 1,
      });
      this.$gtag.event('list-manager-item-move', {
        event_category: 'list-manager',
        event_label: 'list-manager-item-move-down',
      });
    },
    showForm(data) {
      showDialog(ListManagerItemForm, { ...data });

      this.$gtag.event('list-manager-item-edit', {
        event_category: 'list-manager',
      });
    },
    trackOpenSource(url) {
      this.$gtag.event('list-manager-item-source-external', {
        event_category: 'list-manager',
        event_label: url,
      });
    },
    trackOpenPolpo(url) {
      this.$gtag.event('list-manager-item-source-polpo', {
        event_category: 'list-manager',
        event_label: url,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep {
  .custom-description {
    p {
      min-height: 1rem;
      margin-top: 0 !important;
      margin-bottom: 0 !important;
    }
    margin-bottom: 16px !important;
  }
}
</style>
